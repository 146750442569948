<template>
  <div
    ref="wysiwyg"
    :class="styleConfig"
    v-html="html"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
defineProps<{
  html: string;
  styleConfig: Record<string, boolean>;
}>();

const wysiwyg = ref(null);
onMounted(() => {
  if (wysiwyg.value) {
    const pTags = wysiwyg.value.querySelectorAll('p') as HTMLElement[];
    pTags.forEach((tag) => {
      if (
        tag.getElementsByTagName('img').length > 0 ||
        tag.getElementsByTagName('video').length > 0 ||
        tag.getElementsByTagName('iframe').length > 0
      ) {
        tag.replaceWith(...tag.childNodes);
      }
    });

    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(function(anchor) {
      anchor.addEventListener('click', function(event) {
        event.preventDefault();
        const target = anchor?.getAttribute('href').substring(1);
        smoothScroll(target);
      });
    });
  }
});

const smoothScroll = (target: any) => {
  const element = document.getElementById(target);
  if (element) {
    window.scrollTo({
      top: element.offsetTop - 50,
      behavior: 'smooth'
    });
  }
};
</script>
